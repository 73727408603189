var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-4"},[_c('footer',{staticClass:"footer",staticStyle:{"background-color":"rgba(58, 61, 51)"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3 footer-text"},[_c('div',{staticClass:"ml-4"},[_vm._m(0),_c('br'),_vm._m(1),_c('br'),_vm._m(2),_c('br'),_vm._m(3),_vm._v(" "),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("+8801712708936 & +8801316081119")]),_c('br'),_vm._m(4),_vm._v(" "),_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("sreepurgirls.edu.bd@gmail.com")]),_c('br'),_c('ul',{staticClass:"list-unstyled mt-2"},_vm._l((_vm.social_links),function(item,key){return _c('li',{key:key,staticClass:"list-inline-item mr-1"},[_c('a',{staticClass:"rounded",attrs:{"href":item.link,"target":"_blank"}},[_c('img',{staticStyle:{"border-radius":"5px"},attrs:{"src":_vm.imgShow(item.image),"alt":item.name}})])])}),0)])]),_c('div',{staticClass:"col-sm-1"}),_vm._m(5),_c('div',{staticClass:"col-sm-1"}),_c('div',{staticClass:"col-sm-3 footer-text"},[_c('div',{staticClass:"ml-1"},[_vm._m(6),_c('br'),_c('ul',{staticClass:"footer-list mt-1"},_vm._l((_vm.footerByType(3)),function(item,key){return _c('li',{key:key},[(item.is_link)?[_c('a',{staticClass:"footer-text",attrs:{"href":item.link,"target":"_blank"}},[_vm._v(_vm._s(item.title))])]:[_c('router-link',{staticClass:"footer-text",attrs:{"to":item.is_link?item.link:{name:'footer.common',params:{slug:item.slug}},"exact":""}},[_c('i',{staticClass:"uil uil-angle-right-b me-1"}),_vm._v(_vm._s(item.title)+" ")])]],2)}),0)])])])]),_c('footer',{staticClass:"mt-4 footer-text"},[_c('div',{staticClass:"text-sm-center",staticStyle:{"background-color":"#00598b"}},[_c('p',{staticClass:"mb-0",staticStyle:{"font-size":"17px","color":"white"}},[_vm._v("©Copyright "+_vm._s(new Date().getFullYear())+". All Rights Reserved. "),_c('strong',[_vm._v("Sreepur Secondary Girls School")]),_c('br')]),_vm._m(7)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"17px"}},[_c('strong',[_vm._v("Sreepur Secondary Girls School.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"17px"}},[_c('strong',[_vm._v("Sreepur, Magura.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"17px"}},[_c('strong',[_vm._v("Khulna Division, Bangladesh.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"17px"}},[_c('strong',[_vm._v("Mobile:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"17px"}},[_c('strong',[_vm._v("Email:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"border-0"},[_c('div',{staticClass:"card-body p-0"},[_c('iframe',{staticStyle:{"border":"0"},attrs:{"src":"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d936026.2420402085!2d88.24374804687498!3d23.591687500000013!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fef7e1b876f693%3A0x3d59a1b4b318a2f3!2sSreepur%20Secondary%20Girls%20School!5e0!3m2!1sen!2sbd!4v1733481042345!5m2!1sen!2sbd","width":"300","height":"320","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticStyle:{"font-size":"17px"}},[_c('strong',[_vm._v("Important Links")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-0",staticStyle:{"font-size":"17px"}},[_c('span',{staticStyle:{"color":"white"}},[_vm._v("Design & Developed by")]),_vm._v(" "),_c('a',{staticClass:"text-reset",attrs:{"href":"https://optisoftbd.com/","target":"_blank"}},[_c('span',{staticStyle:{"color":"coral"}},[_c('b',[_vm._v("OptiSoft IT Solutions")])])])])
}]

export { render, staticRenderFns }